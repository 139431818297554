
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ideaBoxApi from "@/core/services/IdeaBox";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "ideas",
    components: {},
    data: function () {
        return {
            categories: [],
        };
    },
    methods: {
        getCategories() {
            ideaBoxApi.getCategories().then((response) => {
                this.categories = response.data["categories"];
            });
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs(t("pages.ideas.title"), []);
        return { t };
    },
    watch: {
        "$i18n.locale": function() {
            setCurrentPageBreadcrumbs(this.t("pages.ideas.title"), []);
        },
    },
    created() {
        this.getCategories();
    },
});
